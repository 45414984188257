<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("配送单列表") }}</div>
        <div v-show="form.status_name=='等待付款'" class="consolidatedPaymentContainer">
          <div class="consolidatedPaymentBtn" @click="consolidatedPayment">{{ $fanyi("合并付款") }}</div>
          <el-popover placement="bottom" trigger="hover" width="400">
            <div>
              {{
                $fanyi("您可以勾选多个订单进行合并付款，多个订单只用支付一次，出入金记录里实际账单还是每个订单单独扣款，您可在待支付页面确认每个单子金额明细")
              }}
            </div>
            <div slot="reference" class="questionMark">?</div>
          </el-popover>
        </div>
      </div>
      <div class="orderStatusContainer">
        <div class="flexAndCenter">
          <el-tabs v-model="form.status_name" type="card" @tab-click="form.page = 1; getData(form)">
            <el-tab-pane :label="temporaryText" name="临时保存"></el-tab-pane>
            <el-tab-pane :label="allText" name="全部"></el-tab-pane>
            <el-tab-pane :label="offerText" name="装箱中"></el-tab-pane>
            <el-tab-pane :label="obligationText" name="等待付款"></el-tab-pane>
            <el-tab-pane :label="purchaseText" name="发货中"></el-tab-pane>
            <el-tab-pane :label="shippedText" name="已发货"></el-tab-pane>
            <el-tab-pane :label="receivedText" name="已签收"></el-tab-pane>
          </el-tabs>
          <div class="tabsRightContainer"></div>
        </div>
      </div>
      <div class="flexAndCenter orderScreenContainer">
        <div :style="employeesAccountList.length>0?'margin-bottom:10px':''"
             class="orderKeywordsContainer flexAndCenter">
          <el-input v-model="form.keywords" :placeholder="$fanyi('请输入关键词')"
                    :style="employeesAccountList.length>0?'':'width: 160px;'"/>
        </div>
        <div :style="employeesAccountList.length>0?'margin-bottom:10px':''" class="orderKeywordsContainer flexAndCenter"
             style="margin-left:25px">
          <el-input v-model="form.express_no" :placeholder="$fanyi('请输入物流单号')"
                    :style="employeesAccountList.length>0?'':'width: 160px;'"/>
        </div>
        <div v-if="employeesAccountList.length>0" :style="employeesAccountList.length>0?'margin-bottom:10px':''"
             class="orderKeywordsContainer flexAndCenter" style="margin-left:25px">
          <div class="fontSize14 color333">{{ $fanyi("子账户管理") }}：</div>
          <el-select v-model="form.children_user_id" size="small">
            <el-option
                v-for="item in employeesAccountList"
                :key="item.children_user_id"
                :label="item.children_realname"
                :value="item.children_user_id">
            </el-option>
          </el-select>
        </div>
        <div :style="employeesAccountList.length>0?'':'margin-left:25px'" class="orderTimeContainer flexAndCenter">
          <div class="fontSize14 color333">{{ $fanyi("时间") }}：</div>
          <el-date-picker v-model="form.start_time" :placeholder="$fanyi('开始时间')" default-time="00:00:00"
                          format="yyyy-MM-dd HH:mm:ss" style="width: 176px;" type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="startTimeChange">
          </el-date-picker>
          <div class="fontSize14 color333" style="margin:0 14px">{{ $fanyi("至") }}</div>
          <el-date-picker v-model="form.end_time" :placeholder="$fanyi('结束时间')" default-time="23:59:59"
                          format="yyyy-MM-dd HH:mm:ss" style="width: 176px;" type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="endTimeChange">
          </el-date-picker>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData(form)">{{ $fanyi("搜索") }}</div>
          <div @click="resetOrder">{{ $fanyi("重置") }}</div>
        </div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO"><span class="tableHeadCon">No</span></li>
          <li class="orderNumber">
            <span :title="$fanyi('配送单号')" class="tableHeadCon flexAndCenterAndCenter" style="height: 56px">
              <span style="display: inline-block;height: 56px;line-height: 56px">{{ $fanyi('配送单号') }}</span>
              <img alt="" class="sortIcon" src="../../../../assets/icon/sort.png" @click="updateGoodsSort">
            </span>
          </li>
          <li class="submitTime">
            <span :title="$fanyi('提出日期')" class="tableHeadCon">{{ $fanyi("提出日期") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('配送先')" class="tableHeadCon">{{ $fanyi("配送先") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('物流方式')" class="tableHeadCon">{{ $fanyi("物流方式") }}</span>
          </li>
          <li class="receiverName">
            <span :title="$fanyi('物流单号')" class="tableHeadCon">{{ $fanyi("物流单号") }}</span>
          </li>
          <li class="upLoad">
            <span :title="$fanyi('导出excel')" class="tableHeadCon">{{ $fanyi("导出excel") }}</span>
          </li>
          <li class="orderStatus">
            <span :title="$fanyi('配送单状态')" class="tableHeadCon">{{ $fanyi("配送单状态") }}</span>
          </li>
          <li class="operation">
            <span :title="$fanyi('操作')" class="tableHeadCon">{{ $fanyi("操作") }}</span>
          </li>
        </ul>
        <div v-show="tableData.length > 0">
          <ul v-for="(item, index) in tableData" :key="$fanyi('表格') + index"
              style="border-bottom: 1px solid #DFDFDF;">
            <div class="obody flexAndCenter" style="border-bottom:none">
              <li class="oNO">
                <div>{{ index + 1 }}</div>
                <el-popover
                    v-show="form.status_name=='等待付款'&&item.status_name === '等待付款'&&item.p_porder_sn === ''"
                    placement="right" trigger="focus"
                    width="220">
                  <statistics :checkedList="checkedList"/>
                  <el-checkbox slot="reference" v-model="item.checked" class="cursorPointer"
                               style="margin-top: 2px;"
                               @change="radio(index)"/>
                </el-popover>
              </li>
              <li class="orderNumber">
                <div class="orderNumberContainer">
                  <el-popover v-if="item.children_user!=null" placement="bottom" trigger="hover">
                    <div>
                      <div>社員用アカウント情報</div>
                      <div>氏名：{{ item.children_user.children_realname }}</div>
                    </div>
                    <img slot="reference"
                         class="cursorPointer"
                         src="../../../../assets/subAccount.png"
                         style="margin-top: 4px;margin-right: 5px"
                    />
                  </el-popover>
                  <el-popover v-if="item.p_porder_sn !== ''" placement="bottom" trigger="hover">
                    <div>
                      {{ $fanyi('以下单子在同一个包裹进行发货') }} <br>
                      {{ $fanyi('配送单号') }}：{{ item.p_porder_sn }}
                    </div>
                    <div slot="reference" class="mergeBtnContainer">併</div>
                  </el-popover>
                  <el-popover v-if="item.discard_status==20" placement="bottom" trigger="hover">
                    <div>
                      こちらの配送書の商品が全て廃棄されました。<br>
                      ご疑問点が御座いましたら、担当者にお問い合わせください。
                    </div>
                    <div slot="reference" class="newMergeBtnContainer" style="width: 42px;margin-left:5px">
                      廃棄
                    </div>
                  </el-popover>
                  <router-link :to="{ path: '/deliveryDetails', query: { pOrder_sn: item.porder_sn } }"
                               :style="item.discard_status==20&&item.p_porder_sn !== ''&&item.children_user!=null?'max-width: 115px;display: inline-block;overflow-wrap:break-word;margin: 0 11px 0 7px;':''"
                               class="colorB4272B cursorPointer"
                               style="text-decoration: underline;"
                               target="_blank">
                    {{ item.porder_sn }}
                  </router-link>
                  <div v-show="item.showStatus === false" class="showChildrenBtn"
                       @click="item.showStatus = !item.showStatus">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div v-show="item.showStatus === true" class="showChildrenBtn"
                       @click="item.showStatus = !item.showStatus">
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </li>
              <li class="submitTime">
                <el-popover placement="bottom" popper-class="pOrderTimePopover" trigger="hover">
                  <div class="pOrderTimeListContainer">
                    <div class="pOrderTimeHeaderContainer">
                      <div>{{ $fanyi('提出日期') }}</div>
                      <div>{{ $fanyi('付款日期') }}</div>
                      <div>{{ $fanyi('发货日期') }}</div>
                      <div>{{ $fanyi('签收日期') }}</div>
                    </div>
                    <div class="pOrderTimeFooterContainer">
                      <div class="timeContainer">
                        <div>{{ item.created_at }}</div>
                      </div>
                      <div class="timeContainer">
                        <div>{{ item.datetime_pay_confirm }}</div>
                      </div>
                      <div class="timeContainer">
                        <div>{{ item.datetime_delivery }}</div>
                      </div>
                      <div class="timeContainer">
                        <div>{{ item.datetime_client_receive }}</div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" style="text-decoration: underline;color: #000000;cursor: pointer">
                    {{ item.created_at }}
                  </div>
                </el-popover>
              </li>
              <li class="datePresentation">
                <el-popover placement="top" popper-class="addressPopover" trigger="hover">
                  <div class="flexAndCenter">
                    <div class="address" style="margin-right: 20px">
                      <div class="title">{{ $fanyi("收货地址") }}</div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('氏名') + '：' }}</div>
                        <div :title="item.receiver.name" class="u-line cursorPointer">{{ item.receiver.name }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('ローマ字') + '：' }}</div>
                        <div :title="item.receiver.name_rome" class="u-line cursorPointer">{{
                            item.receiver.name_rome
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('邮编') + '：' }}</div>
                        <div :title="item.receiver.zip" class="u-line cursorPointer">{{ item.receiver.zip }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('手机') + '：' }}</div>
                        <div :title="item.receiver.mobile" class="u-line cursorPointer">{{ item.receiver.mobile }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('住所') + '：' }}</div>
                        <div :title="item.receiver.address" class="cursorPointer">{{ item.receiver.address }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('ローマ字') + '：' }}</div>
                        <div :title="item.receiver.address_rome" class="u-line cursorPointer">
                          {{ item.receiver.address_rome }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('法人番号') + '：' }}</div>
                        <div :title="item.receiver.corporate_name" class="u-line cursorPointer">
                          {{ item.receiver.corporate_name }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('リアルタイム口座') + '：' }}</div>
                        <div :title="item.receiver.account" class="u-line cursorPointer">{{
                            item.receiver.account
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('標準コード') + '：' }}</div>
                        <div :title="item.receiver.standard_code" class="u-line cursorPointer">
                          {{ item.receiver.standard_code }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('公司名') + '：' }}</div>
                        <div :title="item.receiver.company" class="u-line cursorPointer">{{
                            item.receiver.company
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('座机') + '：' }}</div>
                        <div :title="item.receiver.tel" class="u-line cursorPointer">{{ item.receiver.tel }}</div>
                      </div>
                    </div>
                    <div class="address">
                      <div class="title">{{ $fanyi("进口商") }}</div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('氏名') + '：' }}</div>
                        <div :title="item.importer.name" class="u-line cursorPointer">{{ item.importer.name }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('ローマ字') + '：' }}</div>
                        <div :title="item.importer.name_rome" class="u-line cursorPointer">{{
                            item.importer.name_rome
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('邮编') + '：' }}</div>
                        <div :title="item.importer.zip" class="u-line cursorPointer">{{ item.importer.zip }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('手机') + '：' }}</div>
                        <div :title="item.importer.mobile" class="u-line cursorPointer">{{ item.importer.mobile }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('住所') + '：' }}</div>
                        <div :title="item.importer.address" class="cursorPointer">{{ item.importer.address }}</div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('ローマ字') + '：' }}</div>
                        <div :title="item.importer.address_rome" class="u-line cursorPointer">
                          {{ item.importer.address_rome }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('法人番号') + '：' }}</div>
                        <div :title="item.importer.corporate_name" class="u-line cursorPointer">
                          {{ item.importer.corporate_name }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('リアルタイム口座') + '：' }}</div>
                        <div :title="item.importer.account" class="u-line cursorPointer">{{
                            item.importer.account
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('標準コード') + '：' }}</div>
                        <div :title="item.importer.standard_code" class="u-line cursorPointer">
                          {{ item.importer.standard_code }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('公司名') + '：' }}</div>
                        <div :title="item.importer.company" class="u-line cursorPointer">{{
                            item.importer.company
                          }}
                        </div>
                      </div>
                      <div class="flexAndCenter">
                        <div>{{ $fanyi('座机') + '：' }}</div>
                        <div :title="item.importer.tel" class="u-line cursorPointer">{{ item.importer.tel }}</div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" class="cursorPointer u-line">{{ item.receiver.name }}</div>
                </el-popover>
              </li>
              <li class="datePresentation">
                <div>
                  <div v-for="(logisticsItem, logisticsIndex) in item.logistics" :key="logisticsIndex"
                       class="logisticsNameContainer">
                    <div class="logistics_name">{{ logisticsItem.logistics_name }}</div>
                    <el-popover
                        v-if="item.status>=30&&(logisticsItem.logistics_name=='RW船便'||logisticsItem.logistics_name=='Raku-DQ')&&logisticsItem.express_delay.length>0&&logisticsItem.express_delay[0].amount!=0"
                        placement="bottom"
                        trigger="hover">
                      <div style="width: 400px">
                        <div v-if="!$fun.isArray(logisticsItem.node)">引取日：{{ logisticsItem.node.acquire_at }}
                          受領日：{{ logisticsItem.node.arrive_at }}
                        </div>
                        <br v-if="!$fun.isArray(logisticsItem.node)">
                        <div>遅延による還元：{{ logisticsItem.express_delay[0].amount }} 円</div>
                        <br>
                        定額会員限定ルートで、12日間のタイムリミットを設けます。<br>
                        <span v-if="logisticsItem.logistics_name=='RW船便'">詳細は「
                        <el-link href="https://blog.rakumart.com/detail?id=73" target="_blank" type="primary">
                          RW船便説明ページ
                        </el-link>
                        」にご確認下さい。</span>
                      </div>
                      <div slot="reference" class="questionMark" style="margin-left:2px">?</div>
                    </el-popover>
                  </div>
                </div>
              </li>
              <li class="receiverName">
                <div>
                  <div v-for="(logisticsItem, logisticsIndex) in item.logistics" :key="logisticsIndex">
                    <el-popover
                        v-if="logisticsItem.logistics_name == 'TW船便' || logisticsItem.logistics_name == 'KS-JP航空便' || logisticsItem.logistics_name == '海源DQ船便'||logisticsItem.logistics_name == 'RW船便'||logisticsItem.logistics_name == 'Raku-KS' || logisticsItem.logistics_name == 'EMS' || logisticsItem.logistics_name == 'OCS'|| logisticsItem.logistics_name == 'Raku-DQ'|| logisticsItem.logistics_name == 'DQE'"
                        placement="left" popper-class="timelinePopover" style="width: 500px;" trigger="hover">
                      <div style="width: 500px;max-height: 500px;overflow-y: scroll;padding-right: 0;padding-top: 10px">
                        <el-timeline style="margin-left: 2px">
                          <el-timeline-item v-for="(activity, activityIndex) in logisticsItem.activities"
                                            :key="activityIndex"
                                            :color="activity.color"
                                            :style="activityIndex === logisticsItem.activities.length - 1 ? 'padding-bottom:0' : ''"
                                            placement="bottom">
                            <div
                                :style="activityIndex === 0 ? 'color: #B4272B;margin-bottom:8px' : 'margin-bottom:8px;color: #000000;'"
                                class="fontSize14">
                              {{ activity.address }}
                            </div>
                            <div :style="activityIndex === 0 ? 'color: #B4272B;' : ''" class="color999 fontSize12">{{
                                activity.time
                              }}
                            </div>
                          </el-timeline-item>
                        </el-timeline>
                      </div>
                      <div slot="reference" class="logisticsNumber" @click="logisticsPageJump(logisticsItem)"
                           @mouseenter="logisticsMouseenter(logisticsItem)">{{ logisticsItem.express_no }}
                      </div>
                    </el-popover>
                    <div v-else class="logisticsNumber" @click="logisticsPageJump(logisticsItem)">
                      {{ logisticsItem.express_no }}
                    </div>
                  </div>
                </div>
              </li>
              <li class="upLoad"><img src="../../../../assets/downloadIcon.png" @click="porderExcelDownload(item)"/>
              </li>
              <li class="orderStatus" style="display: flex;justify-content: center">
                {{ item.status_name == '已签收' ? '受領済み' : $fanyi(item.status_name) }}
              </li>
              <li class="operation">
                <button v-show="item.status_name === '等待付款' && item.p_porder_sn === ''" class="paymentBtn"
                        @click="$fun.routerToDeliveryDetailsPage(item.porder_sn)">
                  {{ $fanyi("付款") }}
                </button>
                <button v-show="item.status_name === '临时保存'" class="searchBtn" @click="delOrder(item.porder_sn)">
                  {{ $fanyi("删除") }}
                </button>
                <button v-show="item.status_name === '已发货'" class="searchBtn" @click="orderSignFor(item.porder_sn)">
                  {{ $fanyi("签收") }}
                </button>
                <button v-show="item.status_name === '已评价'" class="searchBtn"
                        @click="serviceEvaluationPageJump(item.porder_sn)">
                  {{ $fanyi("查看数据") }}
                </button>
                <button v-show="item.status_name === '已签收'" class="searchBtn"
                        @click="serviceEvaluationPageJump(item.porder_sn)">
                  {{ $fanyi("服务评价") }}
                </button>
                <div v-if="item.isDownload!=undefined&&item.isDownload!=false" class="downloadBtn"
                     @click="download(item)">
                  税金請求書
                </div>
              </li>
            </div>
            <div v-show="item.showStatus === true">
              <div class="ordersListHeader">
                <div class="ordersNumber">{{ $fanyi("订单号") }}</div>
                <div class="goodsNo">{{ $fanyi("番号") }}</div>
                <div class="goodsImage">{{ $fanyi("图片") }}</div>
                <div class="goodsAttribute">{{ $fanyi("商品属性") }}</div>
                <div class="purchaseData">{{ $fanyi("购买数据") }}</div>
                <div class="quantityShipped">{{ $fanyi("发货数") }}</div>
                <div class="option">オプション</div>
                <div class="ordersNumber">倉庫納品情報</div>
                <div class="goodsRemark">{{ $fanyi("备注") }}</div>
              </div>
              <div>
                <div v-if="item.arr==undefined">
                  <div v-for="(orderItem, orderIndex) in item.orderDetailList" :key="orderIndex"
                       class="ordersListFooter">
                    <div class="ordersNumber">
                      <router-link :to="{ path: '/OrderDetails', query: { order_sn: orderItem.order_sn } }"
                                   class="colorB4272B cursorPointer fontSize14" style="text-decoration: underline;"
                                   target="_blank">
                        {{ orderItem.order_sn }}
                      </router-link>
                    </div>
                    <div class="goodsNo">{{ orderItem.sorting }}</div>
                    <div class="goodsImage">
                      <el-popover placement="right" trigger="hover">
                        <el-image :src="orderItem.pic" style="width: 300px; height: 300px"/>
                        <el-image slot="reference" :src="orderItem.pic" style="width: 60px;height: 60px"
                                  @click="$fun.toCommodityDetails(orderItem.goods_id, orderItem.from_platform)"/>
                      </el-popover>
                    </div>
                    <div class="goodsAttribute">
                      <el-popover placement="top" trigger="hover">
                        <div>
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                        <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex" class="u-line">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="purchaseData">
                      <div class="fontSize14">
                        <div>{{ $fanyi('数量') }}：{{ orderItem.confirm_num }}</div>
                        <div>{{ $fanyi('单价') }}：{{ orderItem.confirm_price }}</div>
                        <div>{{ $fanyi('运费') }}：{{ orderItem.confirm_freight }}</div>
                      </div>
                    </div>
                    <div class="quantityShipped fontSize14">{{ orderItem.porder_send_num }}</div>
                    <div class="option">
                      <el-popover v-if="orderItem.option != null && orderItem.option.length > 0" placement="top"
                                  trigger="hover">
                        <div>
                          <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                          </div>
                        </div>
                        <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                          <div class="optionListContainer">
                            <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex"
                                 class="u-line">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div v-if="orderItem.option.length > 3">........</div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="ordersNumber">
                      <el-popover v-if="orderItem.tag != null && orderItem.tag.length > 0" placement="top"
                                  trigger="hover">
                        <div>
                          <div v-for="(tagItem, tagIndex) in orderItem.tag" :key="tagIndex">
                            <div>{{ tagItem.type_translate }}</div>
                            <div>{{ tagItem.no }}</div>
                            <div>{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div slot="reference">
                          <div class="goodsTagDetailsContainer cursorPointer">
                            <div v-for="(tagItem, tagIndex) in orderItem.tag" :key="tagIndex">
                              <div class="u-line">{{ tagItem.type_translate }}</div>
                              <div class="u-line">{{ tagItem.no }}</div>
                              <div class="u-line">{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div v-if="orderItem.tag.length > 1">........</div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="goodsRemark fontSize14">
                      <div :title="orderItem.client_remark" class="u-line-5 cursorPointer">{{
                          orderItem.client_remark
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div style="border-radius: 10px;border:1px solid #FFCF96;overflow:hidden">
                    <div v-for="(goodsItem,goodsIndex) in item.arr" :key="goodsIndex"
                         :style="goodsItem.set_detail_id<0?'background: #FFF0DE;':'background: #FFF8ED;'"
                         class="ordersListFooter"
                         style="background: transparent;">
                      <div class="ordersNumber" style="flex-direction: column;align-items: flex-start!important;">
                        <div v-if="goodsItem.set_detail_id<0" class="setGoodsContainer">组套商品</div>
                        <div v-else class="inBundleGoods"><img alt=""
                                                               src="../../../../assets/link.svg">同捆物{{ goodsIndex }}
                        </div>
                        <router-link :to="{ path: '/OrderDetails', query: { order_sn: goodsItem.order_sn } }"
                                     class="colorB4272B cursorPointer fontSize14" style="text-decoration: underline;"
                                     target="_blank">
                          {{ goodsItem.order_sn }}
                        </router-link>
                      </div>
                      <div class="goodsNo">
                        {{ goodsItem.sorting }}
                      </div>
                      <div class="goodsImage">
                        <el-popover placement="right" trigger="hover">
                          <el-image :src="goodsItem.pic" style="width: 300px; height: 300px"/>
                          <el-image slot="reference" :src="goodsItem.pic" style="width: 60px;height: 60px"
                                    @click="$fun.toCommodityDetails(goodsItem.goods_id, goodsItem.from_platform)"/>
                        </el-popover>
                      </div>
                      <div class="goodsAttribute">
                        <el-popover placement="top" trigger="hover">
                          <div>
                            <div v-for="(detailItem, detailIndex) in goodsItem.detail" :key="detailIndex">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                          <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                            <div v-for="(detailItem, detailIndex) in goodsItem.detail" :key="detailIndex"
                                 class="u-line">
                              {{ detailItem.key }}：{{ detailItem.value }}
                            </div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="purchaseData">
                        <div class="fontSize14">
                          <div>{{ $fanyi('数量') }}：{{ goodsItem.confirm_num }}</div>
                          <div>{{ $fanyi('单价') }}：{{ goodsItem.confirm_price }}</div>
                          <div>{{ $fanyi('运费') }}：{{ goodsItem.confirm_freight }}</div>
                        </div>
                      </div>
                      <div class="quantityShipped fontSize14">{{ goodsItem.porder_send_num }}</div>
                      <div class="option">
                        <el-popover v-if="goodsItem.option != null && goodsItem.option.length > 0" placement="top"
                                    trigger="hover">
                          <div>
                            <div v-for="(optionItem, optionIndex) in goodsItem.option" :key="optionIndex">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                            <div class="optionListContainer">
                              <div v-for="(optionItem, optionIndex) in goodsItem.option" :key="optionIndex"
                                   class="u-line">
                                {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                              </div>
                            </div>
                            <div v-if="goodsItem.option.length > 3">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="ordersNumber">
                        <el-popover v-if="goodsItem.tag != null && goodsItem.tag.length > 0" placement="top"
                                    trigger="hover">
                          <div>
                            <div v-for="(tagItem, tagIndex) in goodsItem.tag" :key="tagIndex">
                              <div>{{ tagItem.type_translate }}</div>
                              <div>{{ tagItem.no }}</div>
                              <div>{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div slot="reference">
                            <div class="goodsTagDetailsContainer cursorPointer">
                              <div v-for="(tagItem, tagIndex) in goodsItem.tag" :key="tagIndex">
                                <div class="u-line">{{ tagItem.type_translate }}</div>
                                <div class="u-line">{{ tagItem.no }}</div>
                                <div class="u-line">{{ tagItem.goods_no }}</div>
                              </div>
                            </div>
                            <div v-if="goodsItem.tag.length > 1">........</div>
                          </div>
                        </el-popover>
                      </div>
                      <div class="goodsRemark fontSize14">
                        <div :title="goodsItem.client_remark" class="u-line-5 cursorPointer">{{
                            goodsItem.client_remark
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-for="(orderItem, orderIndex) in item.orderDetailList" :key="orderIndex"
                       class="ordersListFooter">
                    <div class="ordersNumber">
                      <router-link :to="{ path: '/OrderDetails', query: { order_sn: orderItem.order_sn } }"
                                   class="colorB4272B cursorPointer fontSize14" style="text-decoration: underline;"
                                   target="_blank">
                        {{ orderItem.order_sn }}
                      </router-link>
                    </div>
                    <div class="goodsNo">{{ orderItem.sorting }}</div>
                    <div class="goodsImage">
                      <el-popover placement="right" trigger="hover">
                        <el-image :src="orderItem.pic" style="width: 300px; height: 300px"/>
                        <el-image slot="reference" :src="orderItem.pic" style="width: 60px;height: 60px"
                                  @click="$fun.toCommodityDetails(orderItem.goods_id, orderItem.from_platform)"/>
                      </el-popover>
                    </div>
                    <div class="goodsAttribute">
                      <el-popover placement="top" trigger="hover">
                        <div>
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                        <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex" class="u-line">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="purchaseData">
                      <div class="fontSize14">
                        <div>{{ $fanyi('数量') }}：{{ orderItem.confirm_num }}</div>
                        <div>{{ $fanyi('单价') }}：{{ orderItem.confirm_price }}</div>
                        <div>{{ $fanyi('运费') }}：{{ orderItem.confirm_freight }}</div>
                      </div>
                    </div>
                    <div class="quantityShipped fontSize14">{{ orderItem.porder_send_num }}</div>
                    <div class="option">
                      <el-popover v-if="orderItem.option != null && orderItem.option.length > 0" placement="top"
                                  trigger="hover">
                        <div>
                          <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex">
                            {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                          </div>
                        </div>
                        <div slot="reference" class="goodsAttributeDetailsContainer cursorPointer">
                          <div class="optionListContainer">
                            <div v-for="(optionItem, optionIndex) in orderItem.option" :key="optionIndex"
                                 class="u-line">
                              {{ optionIndex + 1 }}.{{ optionItem.name_translate }}*{{ optionItem.num }}
                            </div>
                          </div>
                          <div v-if="orderItem.option.length > 3">........</div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="ordersNumber">
                      <el-popover v-if="orderItem.tag != null && orderItem.tag.length > 0" placement="top"
                                  trigger="hover">
                        <div>
                          <div v-for="(tagItem, tagIndex) in orderItem.tag" :key="tagIndex">
                            <div>{{ tagItem.type_translate }}</div>
                            <div>{{ tagItem.no }}</div>
                            <div>{{ tagItem.goods_no }}</div>
                          </div>
                        </div>
                        <div slot="reference">
                          <div class="goodsTagDetailsContainer cursorPointer">
                            <div v-for="(tagItem, tagIndex) in orderItem.tag" :key="tagIndex">
                              <div class="u-line">{{ tagItem.type_translate }}</div>
                              <div class="u-line">{{ tagItem.no }}</div>
                              <div class="u-line">{{ tagItem.goods_no }}</div>
                            </div>
                          </div>
                          <div v-if="orderItem.tag.length > 1">........</div>
                        </div>
                      </el-popover>
                    </div>
                    <div class="goodsRemark fontSize14">
                      <div :title="orderItem.client_remark" class="u-line-5 cursorPointer">{{
                          orderItem.client_remark
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div v-show="tableData.length === 0 && loadingStatus === false" class="tableDataNullContainer">
          {{ $fanyi("暂无配送单") }}
        </div>
      </div>
      <div class="flexAndCenter pagination" style="padding: 30px 0;justify-content: center">
        <el-pagination :current-page="form.page" :page-size="form.pageSize" :page-sizes="[10, 20, 30, 40]"
                       :total="total"
                       layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import statistics from "@/views/UserCenter/views/deliveryList/components/statistics.vue";
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      form: {
        status_name: '全部',
        page: 1,
        pageSize: 10,
        keywords: '',
        start_time: '',
        order_by: 'desc',
        express_no: '',
        end_time: '',
        created_by_type: '',
        children_user_id: '',
      },
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        txtcenter: 'center',
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      total: 0,
      checkedList: [],
      oldCheckedList: [],
      temporaryText: this.$fanyi('临时保存') + '（0）',
      deleteText: this.$fanyi('已取消') + '（0）',
      purchasedText: this.$fanyi('采购完了') + '（0）',
      purchaseText: this.$fanyi('发货中') + '（0）',
      obligationText: this.$fanyi('等待付款') + '（0）',
      offerText: this.$fanyi('装箱中') + '（0）',
      allText: this.$fanyi('全部') + '（0）',
      shippedText: this.$fanyi('已发货') + '（0）',
      receivedText: this.$fanyi('配送单已签收') + '（0）',
      tableData: [],
      employeesAccountList: [],
      loadingStatus: null,
    };
  },
  components: {
    statistics,
    Dialog
  },
  created() {
    this.form.page = 1;
    this.form.pageSize = 10;
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case 'temporary':
          this.form.status_name = '临时保存';
          break;
        case 'all':
          this.form.status_name = '全部';
          break;
        case 'offer':
          this.form.status_name = '装箱中';
          break;
        case 'obligation':
          this.form.status_name = '等待付款';
          break;
        case 'purchase':
          this.form.status_name = '发货中';
          break;
        case 'received':
          this.form.status_name = '已签收';
          break;
        case 'shipped':
          this.form.status_name = '已发货';
          break;
      }
    }
    this.getData(this.form);
    this.ordersSum();
    this.$api.childrenUserList({
      page: 1, pageSize: 999
    }).then((res) => {
      res.data.data.forEach((item) => {
        if (item.children_status === 1) {
          this.employeesAccountList.push(item)
        }
      })
    })
  },
  methods: {
    // 获取数据
    getData(data) {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      if (data.children_user_id != '') {
        data.created_by_type = 'children'
      }
      if (this.tableData.length > 0) {
        this.tableData.forEach((tableItem) => {
          if (tableItem.checked && this.checkedList.length == 0) {
            this.checkedList.push(tableItem)
          } else if (this.checkedList.length > 0) {
            for (let i = this.checkedList.length - 1; i >= 0; i--) {
              if (this.checkedList[i].porder_sn == tableItem.porder_sn) {
                if (!tableItem.checked) {
                  this.checkedList.splice(i, 1);
                  this.$forceUpdate();
                  break;
                } else {
                  break;
                }
              } else if (i == 0 && this.checkedList[i].porder_sn != tableItem.porder_sn && tableItem.checked) {
                this.checkedList.push(tableItem)
              }
            }
          }
        })
        this.oldCheckedList = this.$fun.deepClone(this.checkedList);
      }
      this.$api.storageDeliverList(data).then((res) => {
        if (res.code != 0) {
          this.loading.close();
          this.loadingStatus = false;
          return
        }
        if (res.data.data.length > 0) {
          res.data.data.forEach((item, index) => {
            item.showStatus = false;
            item.checked = false;
            let detailArr = [];
            let detailArr2 = [];
            item.orderDetailList = []
            item.order.forEach((items) => {
              items.order_detail.forEach((itemss) => {
                if (itemss.set_detail_id < 0) {
                  item.arr = [];
                  if (detailArr2.length == 0) {
                    detailArr.push({
                      index: index,
                      detailId: this.$fun.deepClone(itemss.id),
                    });
                    item.arr.push(this.$fun.deepClone(itemss));
                  } else {
                    detailArr2.forEach((arrItem) => {
                      if (arrItem.detailId == itemss.id) {
                        item.arr.push(arrItem.info)
                      }
                    })
                  }
                } else if (itemss.set_detail_id > 0) {
                  if (detailArr.length > 0) {
                    detailArr.forEach((arrItem) => {
                      if (arrItem.detailId == itemss.set_detail_id) {
                        item.arr.push(this.$fun.deepClone(itemss))
                      }
                    })
                  } else {
                    detailArr2.push({
                      index: index,
                      detailId: this.$fun.deepClone(itemss.set_detail_id),
                      info: this.$fun.deepClone(itemss)
                    });
                  }
                } else {
                  item.orderDetailList.push(this.$fun.deepClone(itemss))
                }
              })
            })
            item.logistics.forEach((logisticsItem) => {
              if (item.status !== 30 && logisticsItem.logistics_name === 'KS-JP航空便' && logisticsItem.express_no != '') {
                item.ks_express_no = logisticsItem.express_no;
                this.$api.petitionStatus({express_no: logisticsItem.express_no}).then((res) => {
                  item.isDownload = res.data;
                });
              }
              logisticsItem.activities = []
            })
            if (index === res.data.data.length - 1) {
              setTimeout(() => {
                this.tableData = res.data.data;
                this.loading.close();
                if (this.checkedList.length > 0) {
                  this.checkedList.forEach((item) => {
                    this.tableData.forEach((tableItem) => {
                      if (tableItem.porder_sn == item.porder_sn) {
                        tableItem.checked = true;
                      }
                    })
                  })
                }
              }, 1000)
            }
          });
        } else {
          this.tableData = res.data.data;
          this.loading.close();
        }
        this.total = res.data.total;
        this.form.page = res.data.currentPage;
        this.loadingStatus = false;
        this.$forceUpdate();
      })
    },
    //合并付款
    consolidatedPayment() {
      if (this.checkedList.length == 0) return this.$message.error(this.$fanyi('请至少选择一个配送单'));
      let arr = [];
      this.checkedList.forEach((item) => {
        arr.push(item.porder_sn);
      })
      arr = Array.from(new Set(arr));
      let str = '';
      let count = 0;
      arr.forEach((item) => {
        str += item + ',';
        count++;
      })
      str = str.substring(0, str.length - 1);
      if (count > 10) return this.$message.error(this.$fanyi('最多支持10个订单一起付款，请核对已经勾选的订单'));
      this.$fun.routerToPage(`/payment?pOrder_sn=${str}`);
    },
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData(this.form);
    },
    // 单选
    radio(i) {
      this.checkedList = this.$fun.deepClone(this.oldCheckedList);
      this.tableData.forEach((item) => {
        if (this.checkedList.length == 0 && item.checked) {
          this.checkedList.push(item)
        } else if (this.checkedList.length > 0) {
          for (let i = this.checkedList.length - 1; i >= 0; i--) {
            if (this.checkedList[i].porder_sn == item.porder_sn) {
              if (!item.checked) {
                this.checkedList.splice(i, 1);
                this.oldCheckedList = this.$fun.deepClone(this.checkedList);
                this.$forceUpdate();
                break;
              } else {
                break;
              }
            } else if (i == 0 && this.checkedList[i].porder_sn != item.porder_sn && item.checked) {
              this.checkedList.push(item)
            }
          }
        }
      })
      this.$forceUpdate();
    },
    // 获取每个订单数量
    ordersSum() {
      this.$api.storageDeliverNum().then((res) => {
        if (res.data.length == 0) return (this.allText = this.$fanyi('全部') + '（0）');
        res.data.forEach((item) => {
          switch (item.name) {
            case '临时保存':
              this.temporaryText = this.$fanyi('临时保存') + `（${item.count}）`;
              break;
            case '装箱中':
              this.offerText = this.$fanyi('装箱中') + `（${item.count}）`;
              break;
            case '等待付款':
              this.obligationText = this.$fanyi('等待付款') + `（${item.count}）`;
              break;
            case '发货中':
              this.purchaseText = this.$fanyi('发货中') + `（${item.count}）`;
              break;
            case '已发货':
              this.shippedText = this.$fanyi('已发货') + `（${item.count}）`;
              break;
            case '已签收':
              this.receivedText = this.$fanyi('配送单已签收') + `（${item.count}）`;
              break;
            case '全部':
              this.allText = this.$fanyi('全部') + `（${item.count}）`;
              break;
          }
        })
      })
    },
    download(item) {
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("数据导出中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      var xhr = new XMLHttpRequest();
      let url = websiteUrl + '/client/porder.downloadTaxDetails';
      xhr.open("post", url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader('accept', '*/*');
      xhr.setRequestHeader('accept-language', 'zh-CN');
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      let user_token = localStorage.getItem('japan_user_token')
      xhr.setRequestHeader("ClientToken", 'Bearer ' + user_token);
      let that = this;
      xhr.onload = function () {
        if (this.status == 200 && this.response.type != "text/html" && this.response.type !== 'application/json') {
          if (window.navigator.msSaveOrOpenBlob) { //IE浏览器
            navigator.msSaveBlob(this.response, `${this.ks_express_no}千速税金请求书`);
          } else {//非IE浏览器
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(this.response);
            link.download = `${item.ks_express_no}千速税金请求书`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            that.loading.close();
            that.$forceUpdate();
          }
        } else {
          that.$message.error('操作が失敗しました');
          that.loading.close();
        }
      };
      xhr.send(`express_no=${item.ks_express_no}`);
    },
    //服务评价页面跳转
    serviceEvaluationPageJump(porder_sn) {
      let routeData = this.$router.resolve({
        path: "/serviceEvaluation",
        query: {
          porder_sn: porder_sn,
        },
      });
      window.open(routeData.href, "_blank");
    },
    //获取配送单导出需要的临时token，下载excel
    porderExcelDownload(item) {
      this.$api.getPorderDownloadToken({
        porder_sn: item.porder_sn
      }).then((res) => {
        const a = document.createElement('a');
        a.href = `${websiteUrl}/client/download.porderDetailDownload?porder_sn=${item.porder_sn}&downloadToken=${res.data.downloadToken}`
        a.target = '_blank';
        document.body.appendChild(a)
        a.click()
      });
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    startTimeChange() {
      if (this.form.end_time !== '') {
        if (this.form.end_time < this.form.start_time) {
          this.$message.error(this.$fanyi("开始时间不能晚于结束时间"));
          this.form.start_time = '';
        }
      }
    },
    //判断结束时间不能早于开始时间
    endTimeChange() {
      if (this.form.start_time !== '') {
        if (this.form.start_time > this.form.end_time) {
          this.$message.error(this.$fanyi("结束时间不能早于开始时间"));
          this.form.end_time = '';
        }
      }
    },
    //重置订单筛选条件
    resetOrder() {
      this.form = {
        status_name: this.form.status_name,
        page: 1,
        pageSize: 10,
        keywords: '',
        express_no: '',
        children_user_id: '',
        created_by_type: '',
        start_time: '',
        end_time: ''
      };
      this.getData(this.form);
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
    //获取国际物流信息
    logisticsMouseenter(logisticsItem) {
      if (logisticsItem.activities.length === 0) {
        this.$api.getLogisticsTrack({
          express_no: logisticsItem.express_no
        }).then((res) => {
          if (res.code != 0) return
          logisticsItem.activities = res.data.address_data
        });
      }
    },
    // 删除订单,根据订单状态分为硬删和软删
    delOrder(order_sn) {
      this.$refs.dialog.open(
          this.$fanyi("是否取消该配送单？"),
          () => {
            let datas = {
              porder_sn: order_sn,
            };
            this.$api.storageTampDeliverDelete(datas).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.$message.success(res.msg)
              this.getData(this.form);
              this.ordersSum();
            });
          },
          () => {
          }
      );
    },
    //物流跳转
    logisticsPageJump(val) {
      if (val.logistics_select.href !== '') {
        let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
        oInput.value = val.express_no; //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
        this.$message.success('追跡番号をコピーしました');
        if (val.logistics_select.method === 'get') {
          let str = '?';
          let url = this.$fun.deepClone(val.logistics_select.href);
          Object.keys(val.logistics_select.data).forEach((key, index) => {
            if (index === 0) {
              str += key + '=' + val.logistics_select.data[key]
            } else {
              str += '&' + key + '=' + val.logistics_select.data[key]
            }
          });
          url += str;
          window.open(url)
        } else {
          var postForm = document.createElement("form");//创建form表单
          postForm.method = "post";//设置请求方式
          postForm.action = val.logistics_select.href;//设置url
          postForm.target = '_blank';//用来将数据提交到ifrom上（将页面展示在ifrom），删掉这一行会导致页面跳转
          Object.keys(val.logistics_select.data).forEach((key) => {
            var custGroup = document.createElement("input");//创建input框，用以提交参数
            custGroup.type = "hidden";//设置input样式
            custGroup.name = key;//这个是传值的名称，要记住
            custGroup.value = val.logistics_select.data[key]//传值
            postForm.appendChild(custGroup);//添加input框到form表单中
          });
          document.body.appendChild(postForm);//添加form表单到body
          postForm.submit();//提交form表单
          document.body.removeChild(postForm);//将body中的form表单删除
        }
      }
    },
    //配送单签收
    orderSignFor(order_sn) {
      this.$refs.dialog.open(
          this.$fanyi("是否签收该配送单？"),
          () => {
            this.$api.internationalLogisticsClientReceive({porder_sn: order_sn}).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.$message.success(this.$fanyi("签收成功"));
              this.getData(this.form);
              this.ordersSum();
            });
          },
          () => {
            this.$message(this.$fanyi("已取消签收"));
          }
      );
    },
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.downloadBtn {
  width: 100px;
  height: 34px;
  background: #2742B4;
  border-radius: 8px;
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.logisticsNameContainer {
  display: flex;
  align-items: center;

  .logistics_name {
    font-size: 12px;
    width: 80px;
  }
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ordersListHeader {
  display: flex;
  background: #F9F9F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  div {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    height: 42px;
    line-height: 42px;
  }
}

.ordersListFooter {
  display: flex;
  background: #FFF8EE;
  height: 120px;

  .ordersNumber,
  .goodsNo,
  .goodsImage,
  .goodsAttribute,
  .purchaseData,
  .quantityShipped,
  .option,
  .goodsRemark {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ordersNumber {
  width: 180px;

  .setGoodsContainer {
    width: 68px;
    height: 28px;
    background: #FFDFB8;
    border-radius: 4px;
    font-size: 12px;
    color: #F08804;
    text-align: center;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .inBundleGoods {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    color: #F08804;

    img {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }

  .goodsTagDetailsContainer {
    width: 172px;
    height: 53px;
    overflow: hidden;

    div {
      text-align: left;
      color: #333333;
      font-size: 14px;
      width: 172px;
    }
  }
}

.goodsNo {
  width: 50px;
}

.goodsImage {
  width: 88px;
}

.goodsAttribute {
  width: 150px;
}

.goodsAttributeDetailsContainer {
  width: 119px;
  height: 48px;

  .optionListContainer,
  .detailListContainer {
    width: 119px;
    height: 56px;
    overflow: hidden;
  }

  div {
    text-align: left;
    color: #333333;
    font-size: 14px;
    width: 119px;
  }
}

.purchaseData {
  width: 120px;
}

.quantityShipped {
  width: 80px;
}

.option {
  width: 140px;
}

.goodsRemark {
  width: 114px;
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .consolidatedPaymentContainer {
      display: flex;
      align-items: center;

      .consolidatedPaymentBtn {
        width: 120px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        background: #B4272B;
        color: #fff;
        margin-left: 10px;
      }
    }
  }

  .tabsRightContainer {
    height: 40px;
    border-bottom: 1px solid #B4272B;
    margin-top: -14px;
    flex: 1;
  }

  /deep/ .el-tabs__header {
    border-bottom: none;
  }

  /deep/ .is-active {
    border-top: 1px solid #B4272B !important;
    border-right: 1px solid #B4272B !important;
    border-left: 1px solid #B4272B !important;
    border-bottom: 1px solid transparent !important;
  }

  /deep/ .el-tabs__item {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #B4272B;
  }

  /deep/ .el-tabs__nav {
    border: none;
  }

  .orderStatusContainer {
    margin: 26px 26px 0;
    position: relative;

  }

  .orderScreenContainer {
    margin-left: 26px;
    padding-bottom: 15px;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;
    flex-wrap: wrap;

    .cancelBtnListContainer {
      width: 100px;
      height: 30px;
      background: #B4272B;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-left: 10px;
    }

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
      }
    }

    .orderTimeContainer {
      /deep/ .el-input__inner {
        width: 176px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        padding-left: 13px;
        padding-right: 0;
      }

      /deep/ .el-date-editor {
        .el-input__prefix {
          display: none;
        }
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }

      div:first-child {
        color: #fff;
        background: #B4272B;
        margin-right: 10px;
      }

      div:last-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .dataBody {
    margin: 20px 26px 0;

    .oNO {
      width: 42px;
    }

    .orderNumber {
      width: 274px;

      .orderNumberContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          text-decoration: underline;
          color: #B4272B;
        }

        .mergeBtnContainer, .newMergeBtnContainer {
          width: 30px;
          height: 30px;
          background: #B4272B;
          border-radius: 6px;
          text-align: center;
          cursor: pointer;
          line-height: 30px;
          color: #FFFFFF;
          font-size: 14px;
        }

        .showChildrenBtn {
          height: 30px;
          background: #F0F0F0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          cursor: pointer;

          .el-icon-arrow-right:before,
          .el-icon-arrow-down:before {
            font-weight: bold;
            font-size: 14px;
          }

          div {
            color: #333333;
            font-size: 14px;
          }
        }
      }

      .sortIcon {
        cursor: pointer;
        width: 20px;
        margin-left: 20px;
      }
    }

    .submitTime {
      width: 120px;

    }

    .datePresentation {
      width: 100px;
    }

    .receiverName {
      width: 156px;

      .cursorPointer {
        width: 156px;
        text-align: center;
        color: #2742B4;
        text-decoration: underline;
      }

      .logisticsNumber {
        cursor: pointer;
        color: #2742B4;
        text-decoration: underline;
        font-size: 14px;
      }

      .activityTime {
        font-size: 12px;
        color: #999999;
      }
    }

    .upLoad {
      width: 90px;

      img {
        cursor: pointer;
      }
    }

    .orderStatus {
      width: 120px;
    }

    .operation {
      width: 100px;

      .searchBtn {
        cursor: pointer;
        color: #B4272B;
        text-decoration: underline;
      }

      .paymentBtn {
        width: 80px;
        height: 26px;
        background: #B4272B;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        line-height: 26px;
        color: #FEFEFE;
        font-size: 12px;
      }
    }

    .head {
      justify-content: center;
      background: #F0F0F0;
      font-size: 14px;
      color: #333;
      height: 56px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
    }

    .obody {
      text-align: center;
      min-height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>
